.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.p-inputtext {
  padding: 0.5rem; /* Adjust as needed */
}

.p-calendar .p-inputtext {
  padding: 0.5rem; /* Adjust as needed */
}


.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  margin-top: 55px !important;
  width: 235px !important;
  margin-left: 24px !important;
  height: 48px !important; 
}

.MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault.css-17o22dy-MuiAvatar-root{
  margin-left: 36px;
 margin-top:1px
}
.p-menuitem-link{
  color: #18406E;
  border-left: 4px solid #18406E;
  
}

.border-left{
  border-left: 4px solid #18406E;
}

.pi-calendar:before {
  content: "\e927";
  margin-right: 10px;
}
.p-paginator.p-component{
   width: 465px;
}
.search-input-wrapper {
  position: relative;
  width: max-content;
}

.search-input-wrapper .pi-search {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.search-input {
  padding-right: 30px; /* Adjust the padding to prevent text from hiding behind the icon */
}

@keyframes floatAnimation {
  0% {
    transform: translateY(0);
    box-shadow: 0 5px 15px #00B2DD0D;
  }
  50% {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px #00B2DD0D;
  }
  100% {
    transform: translateY(0);
    box-shadow: 0 5px 15px #00B2DD0D;
  }
}

.selected-item {
  animation: floatAnimation 2s ease-in-out infinite;
  border: 2px solid #004172; /* Highlight color, adjust as needed */
}
.radio-button-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

/* Add border around the radio button */
.p-radiobutton-box {
  border: 1px solid #aaa;
}
.radio-button-item {
  margin-right: 30px; /* Adjust margin as needed */
}
/* Add border around the checked radio button */
.p-radiobutton-box.p-highlight {
  border-color: #007bff;
}




.loginImage {
  position: absolute;
  left: 7rem;
}