@tailwind base;
@tailwind components;
@tailwind utilities;


@import "primeicons/primeicons.css";
 
@import "primereact/resources/themes/lara-light-indigo/theme.css";
 
@import "primereact/resources/primereact.css";

body {
    font-family: "Poppins", sans-serif;
}

.primary-button {
    background-color: #004172
    ; /* Primary button background color */
    color: white; /* Text color */
    padding: 10px 20px; /* Padding */
    border: none; /* No border */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Cursor style */
  }
  
  .primary-button:hover {
    background-color: #004172
    ; /* Darker shade on hover */
  }

  .custom-rounded {
    border-radius: 1rem 0 0 1rem
  }
  .css-1rvyn53-MuiTableCell-root{
    text-align: center  !important
  }

  #quantity::-webkit-outer-spin-button,
#quantity::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#quantity {
  -moz-appearance: textfield;
}
.divider {
  height: 30px;
  width: 1px;
  background-color: #e0e0e0;
  margin: 0;
}
.css-gwapp2-MuiTableCell-root {
  font-family: var(--font-family) !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
}